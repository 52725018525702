/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');
                // Mobile Navigation
                $('.c-toggle--nav').click(function (e) {
                    $(this).toggleClass('is-active');

                    $('.c-nav--mobile .c-nav__list').slideToggle();
                });
                //
                //About Us Images
                //
                var colDesktop1 = $('.c-content--about').data('desktop'),
                    colMobile1 = $('.c-content--about').data('mobile');
                if (viewportWidth < 1024) {
                    $('.c-content--about').css('background-image', 'url(' + colMobile1 + ')');
                } else {
                    $('.c-content--about').css('background-image', 'url(' + colDesktop1 + ')');
                }
                //
                //Testimonial Images
                //
                var colDesktop2 = $('.c-content--testimonials').data('desktop'),
                    colMobile2 = $('.c-content--testimonials').data('mobile');
                if (viewportWidth < 1024) {
                    $('.c-content--testimonials').css('background-image', 'url(' + colMobile2 + ')');
                } else {
                    $('.c-content--testimonials').css('background-image', 'url(' + colDesktop2 + ')');
                }

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    // tasks to do if it is a Mobile Device
                    $(function () {
                        var children = $('.c-nav__list > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
                        $('<span class="drop-menu"><i class="fas fa-sort-down"></i></span>').insertAfter(children);
                        $('.c-nav__list .drop-menu').click(function (e) {
                            $(this).next().slideToggle(300);
                            return false;
                        });
                    });

                }
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Landing page, note the change from about-us to about_us.
        'page_template_landing_page': {
            init: function () {
                // JavaScript to be fired on the about us page
                // Handle hero backgrounds
                var hero = $('.hero');
                var heroMobileBG = hero.data('mobile');
                var heroDesktopBG = hero.data('desktop');

                if (window.matchMedia('(max-width: 1023px)').matches) {
                    if (heroMobileBG) {
                        hero.css('background-image', 'url(' + heroMobileBG + ')');
                    }
                } else {
                    if (heroDesktopBG) {
                        hero.css('background-image', 'url(' + heroDesktopBG + ')');
                    }
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
